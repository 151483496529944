import React, {useState} from "react";
import { Row, Col, Button, FormGroup, Input} from "reactstrap";
import axios from "axios";
import {toast} from "react-toastify";
import { useNavigate } from "react-router-dom";
import { registerAccountApi } from "../../config";

const registerAccountUrl = registerAccountApi();
const initialUser = {email: "", password: "", username: "", signAs: ""};

const Registration =() => {
    const [user, setUser] = useState(initialUser);
    const navigate = useNavigate();

    const signUp = async() => {
        try{
            user.signAs ="account";
            if(user.username && user.email && user.password && user.signAs){
                const res = await axios.post(registerAccountUrl, user);
                //console.log({res});
                if(res){
                    setUser(initialUser);
                    navigate("/login");
                }
            }
        }catch(error){
            toast.error("Please verify your email first!", {
                hideProgressBar: true,
            });
        }
    };

    const handleUserChange = ({target}) => {
        const {name, value} = target;
        setUser((currentUser)=>({
            ...currentUser,
            [name]:value,
        }));
    };

    return (
        <Row className="register">
            <Col sm="12" md={{size:4, offset:4}}>
                <div>
                    <h2>Sign up:</h2>
                        <FormGroup>
                            <Input 
                            type="text" 
                            name="username" 
                            value={user.username} 
                            onChange={handleUserChange} 
                            placeholder="Enter your full name" 
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input 
                            type="email" 
                            name="email" 
                            value={user.email} 
                            onChange={handleUserChange} 
                            placeholder="Enter your email" 
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input 
                            type="password" 
                            name="password" 
                            value={user.password} 
                            onChange={handleUserChange} 
                            placeholder="Enter password" 
                            />
                        </FormGroup>
                        <Button color="primary" onClick={signUp}>
                            Sign up
                        </Button>
                </div>
            </Col>
        </Row>
      );
}

export default Registration;