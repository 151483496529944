import './table.css'
import { v4 } from 'uuid'
import {host, getGetCurrentSeasonApi, getReceiptsApi, updateReceiptApi, getConfigApi, env } from "../../config";
import axios from "axios";
import {Button} from 'reactstrap';
import { useEffect, useState } from "react";
import {userData} from "../../helpers";
import moment from 'moment/moment';

const currentEnvironment = env();

function debug (message=null, variable=null){

  if(currentEnvironment !== "prod"){
    if(variable) {
      console.log(message, variable);
    }
    else {
      console.log(message);
    }
  }
}

function RefreshPage() {
  window.location.reload();
}

export const refreshPage = () => {
  return RefreshPage;
}

const disableApprovalButtons = (item, disableStatus) => {
  if(item.attributes.processed_by_cron_job === null){
    if(item.attributes.status === disableStatus){
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return true;
  }
}

const updateReceipt = async(receipt_id, approved, pageId) =>{
  debug("receipt_id", receipt_id);
  const {username, jwt} = userData();
  const url = updateReceiptApi (receipt_id);

  localStorage.setItem('pageId', pageId);
  debug("current page id", pageId);

  await axios.put(url, {
    data: {
      status: `${approved === true ? "manual_approved" : "manual_rejected"}`,
      approver: username,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  .then(response => {
    // handle success
    debug(response.data);
    window.location.reload();
  })
  .catch(error => {
    // handle error
    debug(error);
  });

};

const convert_date_format = (datetime) => {
  var date = datetime;
  var mtDate = moment(date);
  var hongKongTime = mtDate.utcOffset('+0800');
  //debug("original date", date);
  var newDateFormat = moment(hongKongTime).format('YYYY-MM-DD HH:mm');
  //debug("newDateFormat", newDateFormat);
  return newDateFormat;
}


const getGetCurrentSeasonUrl = getGetCurrentSeasonApi();
const getReceiptsUrl = getReceiptsApi();
const getConfigUrl = getConfigApi();
const getHostName = host();

var configApi = null;

const loadServerConfigApi = async() => {
  await axios.get(getConfigUrl)
  .then(response => {
    // handle success
    configApi = response.data.data[0];
    debug("config Api", configApi);
  })
  .catch(error => {
    // handle error
    debug(error);
  });
}

var typeLists = [];
const loadAllContentToLists = async() => {

  if(localStorage.getItem('typeList_0') &&
     localStorage.getItem('typeList_1') &&
     localStorage.getItem('typeList_2'))
  {
    typeLists[0] = JSON.parse(localStorage.getItem('typeList_0'));
    typeLists[1] = JSON.parse(localStorage.getItem('typeList_1'));
    typeLists[2] = JSON.parse(localStorage.getItem('typeList_2'));

    debug("local typelist"+ 0, typeLists[0]);
    debug("local typeList"+ 1, typeLists[1]);
    debug("local typeList"+ 2, typeLists[2]);
  }
  else {
    let baseApi = [
      getHostName+"/api/receipt-type-ones", 
      getHostName+"/api/receipt-type-twos", 
      getHostName+"/api/receipt-type-threes"]

    let Apis = [
      getHostName+"/api/receipt-type-ones?pagination[pageSize]=", 
      getHostName+"/api/receipt-type-twos?pagination[pageSize]=", 
      getHostName+"/api/receipt-type-threes?pagination[pageSize]="];

    var tatalItems = [0, 0, 0];
    typeLists = [null, null, null];
  
      const requests = baseApi.map((url, i) => axios.get(url).then(response => {
        tatalItems[i] = response.data.meta.pagination.total;
        debug("tatalItems"+ i, tatalItems[i]);

        return axios.get(Apis[i]+tatalItems[i]).then(response => {
        typeLists[i] = response.data.data;
        localStorage.setItem('typeList_'+i, JSON.stringify(typeLists[i]));

        debug("reload typeList"+ i, typeLists[i]);
        });
      }));
      await Promise.all(requests);
  }  
}


const getShopName = (input_point, name, input_type_list_id, input_type_list_lang) => {
  if(input_point === 2 || input_point === 3)
    return name;
  else {
    if(typeLists[0]) {
      for(let i=0; i< typeLists[0].length;i++){
        if(typeLists[0][i].id === input_type_list_id){
          if(input_type_list_lang === "chi"){
            name = typeLists[0][i].attributes.brand_name;
          }
          else if(input_type_list_lang === "eng"){
            name = typeLists[0][i].attributes.brand_name_eng;
          }
          return name;
        }
      }
    }
  }
}


const getItemName = (input_point, input_type_list_id, input_type_list_lang) => {
  var name = '';
  if(typeLists[input_point - 1]) {
    for(let i=0; i< typeLists[input_point - 1].length;i++){
      if(typeLists[input_point - 1][i]){
        if(input_point === 1 || input_point === 3) {
          if(typeLists[input_point - 1][i].id === input_type_list_id)
          {
            switch(input_point){
              case 1:
                if(input_type_list_lang === "chi"){
                  name = typeLists[input_point - 1][i].attributes.food_name;
                }
                else if(input_type_list_lang === "eng"){
                  name = typeLists[input_point - 1][i].attributes.food_name_eng;
                }
                return name;
              case 3:
                name = typeLists[input_point - 1][i].attributes.dish_name;
                return name;
              default:
                return null;
            }
          }
        }
        else {
          return typeLists[input_point - 1][i].attributes.title;
        }
      }
    } 
  }
}

const show_add_points = (item) => {
  if(item) {
    if(item.attributes.added_point !== null && item.attributes.added_point !== ""){
      return item.attributes.added_point;
    }
    else {
      return "/";
    }
  }
}


const remaining_days_to_addpoint = (item) => {
  var status = item.attributes.status;
  var added_point = item.attributes.added_point;
  var processed_by_cron_job = item.attributes.processed_by_cron_job;
  var lastupdatedDate = convert_date_format(item.attributes.updatedAt);
  var actionRequireDays = 0;
  //debug("status", status);
    
  // eslint-disable-next-line default-case
  if(status && configApi && added_point === 0 && processed_by_cron_job === null){
    switch(status){
      case "auto_approved_by_match":
        actionRequireDays = configApi.attributes.auto_approved_by_match_action;
        break;
      case "awaiting_approval":
        actionRequireDays = configApi.attributes.awaiting_approval_action;
        break;
      case "manual_approved":
        actionRequireDays = configApi.attributes.manual_approved_action;
        break;
      case "manual_rejected":
        actionRequireDays = configApi.attributes.manual_rejected_action;
        break;
      default:
        actionRequireDays = -1;
        break;
    }

    if(actionRequireDays !== -1) {
      actionRequireDays += 1; // as config is > the actionRequireDays, so need to add back one more day

      var originDate = moment(lastupdatedDate);
      var targetDate = originDate.add(actionRequireDays, 'days');
      var newTargetDate = moment(targetDate).format('YYYY-MM-DD HH:mm:ss');
      var today = moment().format('YYYY-MM-DD HH:mm:ss');
      var diffInDays  = moment(newTargetDate).diff(moment(today), 'days');

      if(status === "manual_rejected"){
        if(diffInDays === 0){
          return "/";
        }
        else {
          return diffInDays;
        }
      }
      else {
        return diffInDays;
      }

    }
    else {
      return 0;
    }
  }
  else {
    if(added_point === null && processed_by_cron_job === null){
      return "NA, old format of receipt";
    }
    else {
        if(status === "manual_rejected"){
          return "/";
        }
        else {
          return 0;
        }
            
    }
  }
}

const DataTable = () => {

  const Image = ({ src, ref_id }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const handleFullscreen = () => {
      const image = document.getElementById(ref_id);
      !isFullscreen ? image.requestFullscreen():document.exitFullscreen();
      setIsFullscreen(!isFullscreen);
      //debug(ref_id, src);
    };

    return (
      <div>
        <img id={ref_id} 
         src={src} 
         alt={src === null ? 'missing image url': ref_id} 
         width="80" 
         height="120" 
         onError={(e) => { e.target.src = 'https://www.analyticdesign.com/wp-content/uploads/2018/07/unnamed.gif' }} 
         onClick={handleFullscreen} 
        />
      </div>
    );
  };

  const TableHeadItem = ({ item }) => <th>{item.heading}</th>
  const TableRow = ({ item, column, pageId}) => (
    <tr>
      {column.map((columnItem) =>{
        if(columnItem.value.includes('.')) {
          const itemSplit = columnItem.value.split('.');
          if(item && item[itemSplit[0]] && item[itemSplit[0]][itemSplit[1]]){
            return <td key={v4()}>{item[itemSplit[0]][itemSplit[1]]}</td>
          }
        }
        else if(columnItem.value.includes('input_shop_name')){
          return <td key={v4()}><div>{getShopName(item.attributes.input_point, item.attributes.input_shop_name, item.attributes.input_type_list_id, item.attributes.input_type_list_lang)}</div></td>;
        }
        else if(columnItem.value.includes('input_item_name')){
          return <td key={v4()}><div>{getItemName(item.attributes.input_point, item.attributes.input_type_list_id, item.attributes.input_type_list_lang)}</div></td>;
        }
        else if(columnItem.value.includes('recipt_s3_arn')){
          return <td key={v4()}>
            {
              <Image 
              src={item.attributes.recipt_s3_arn} 
              ref_id={item.attributes.receipt_ref_id} 
             />
            }
         </td>;
        }
        else if(columnItem.value.includes('approval')){
          return <td key={v4()}>
            <div><Button className='approveButton' disabled={disableApprovalButtons(item, "manual_approved") ? true:false} onClick={()=> updateReceipt(item.attributes.id, true, pageId)}>Approval</Button></div><br/>
            <div><Button className='rejectButton' disabled={disableApprovalButtons(item, "manual_rejected") ? true:false} onClick={()=> updateReceipt(item.attributes.id, false, pageId)}>Rejected</Button></div>
          </td>;
        }
        else if(columnItem.value.includes('submit_datetime')){
          return <td key={v4()}>
          <div>{convert_date_format(item.attributes.publishedAt)}</div>
          </td>;
        }
        else if(columnItem.value.includes('last_update_time')){
          return <td key={v4()}>
          <div>{convert_date_format(item.attributes.updatedAt)}</div>
          </td>;
        }
        else if(columnItem.value.includes('added_point')){
          return <td key={v4()}>
          <div>{show_add_points(item)}</div>
          </td>;
        }
        else if(columnItem.value.includes('remaining_days_to_addpoint')){
          return <td key={v4()}>
              <div>{remaining_days_to_addpoint(item)}</div>
          </td>;
        }
        else if(columnItem.value.includes('manual_reject_rate') && item.owner){
          const rejectRateColor = (value)=> {
            if(value <= 25)
              return 'green';
            else if(value > 25 && value <= 50)
              return 'yellow';
            else if(value > 50 && value <= 75)
              return 'orange';
            else if(value > 75)
              return 'red';
          }

          return <td key={v4()} style={{
            backgroundColor: rejectRateColor(item.owner.manual_reject_rate), 
            color: 'black', 
            fontSize:"15px",
            fontWeight: 'bold'
            }}>
            <span>{(item.owner.manual_reject_rate * 100).toFixed(2)} %</span>
          </td>;
        }
        return <td key={v4()}>{item[`${columnItem.value}`]}</td>
      })}
    </tr>
  )

  const column = [
    { heading: 'id', value: 'attributes.id' },
    { heading: 'User id', value: 'attributes.user_id' },
    { heading: 'Owner', value: 'owner.username' },
    { heading: 'Receipt Type', value: 'attributes.input_point' },
    { heading: 'Receipt Number', value: 'attributes.input_receipt_number' },
    { heading: 'Shop/Brand Name', value: 'input_shop_name' },
    { heading: 'Item Name', value: 'input_item_name'},
    { heading: 'Recipt Image', value: 'recipt_s3_arn' },
    { heading: 'Status', value: 'attributes.status' },
    { heading: 'Last Approver', value: 'attributes.approver' },
    { heading: 'Record Date', value: 'attributes.input_date' },
    { heading: 'Submite Date', value: 'submit_datetime' },
    { heading: 'Last Update Time', value: 'last_update_time' },
    { heading: 'added point', value: 'added_point'},
    { heading: 'Remaining days to add points', value: 'remaining_days_to_addpoint' },
    { heading: 'Manual-Reject-Rate', value: 'manual_reject_rate'},
    { heading: 'Approval', value: 'approval' },
  ]

  const [data, setData] = useState([]);
  const previousPage = localStorage.getItem('pageId');
  const [page, setPage] = useState(previousPage? parseInt(previousPage) : 1);
  //const [totalReceipts, setTotalReceipts] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [inputPage, setInputPage] = useState(1);
  const [filterType, setFilterType] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const limit = 10;
  const previousDateSort = localStorage.getItem('dateSort');
  const previousSearchTerm= localStorage.getItem('searchTerm');
  const previousFilterParam = localStorage.getItem('filterParam');
  const previousFilterStatus = localStorage.getItem('filterStatus');

  const [searchTerm, setSearchTerm] = useState(previousSearchTerm? previousSearchTerm: '');
  const [selectedFilterParam, setSelectedFilterParam] = useState(previousFilterParam? previousFilterParam : '');
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(previousFilterStatus? previousFilterStatus : '');
  const [orderBy, setOrderBy] = useState(previousDateSort? previousDateSort: 'DESC');

  const fetchData = async (receiptStatus, filterType, filterValue, seasonId, orderBy, limit, page) => {
    try {
      var filters = {};
      var res ="";
      var receipts = [];

      switch(filterType){
        case "receipt_id":
          filters.id = filterValue;
          break;
        case "user_id":
          filters.user_id = filterValue;
          break;
        case "input_point":
          filters.input_point = filterValue;
          break;
        case "input_receipt_number":
          filters.input_receipt_number = filterValue;
          break;
        case "input_shop_name":
          filters.input_shop_name = filterValue;
          break;
        case "added_point":
          filters.added_point = filterValue;
          break;
        case "owner":
          filters.owner = {username: filterValue}
          break;
        default:
          //filters = {season: seasonId};
          filters.owner = {username: ""}
          break;
      }

      if(receiptStatus !== '')
         filters.status = receiptStatus;
      
      res = await axios.get(getReceiptsUrl, {
        params: {
          filters,
          sort: {publishedAt: `${orderBy}`},
          pagination: {
            limit,
            start: (page - 1) * limit
          }
        }
      });

      receipts = res.data.data.map((receipt, index) => {
        receipt.rowId = index;
        if (receipt.attributes.owner) {

          receipt.owner = receipt.attributes.owner;
        }
        return receipt;
      });
  
      return {
        data: receipts,
        pagination: res.data.meta.pagination,
        total: res.data.meta.pagination.total
      };

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const loadReceipts = async () => {
      try {
        loadAllContentToLists();
        loadServerConfigApi();
        const seasonRes = await axios.get(getGetCurrentSeasonUrl);
        const seasonId = seasonRes.data.currentSeason.id;
        const receipts = await fetchData(selectedFilterStatus, filterType, filterValue, seasonId, orderBy, limit, page);
        debug(receipts);
        
        if(receipts.data){
          setData(receipts.data);
          setPageCount(receipts.pagination.pageCount);
        }
        else {
          setData([]);
        }

        if((previousSearchTerm !== null &&
            previousFilterParam !== null) || 
            previousFilterStatus !== null){
            handleFilter(false);
        }

      } catch (error) {
        console.error(error);
      }
    };
  
    loadReceipts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, orderBy, filterType, filterValue, selectedFilterStatus]);


  const handleFilterParams = async(filterType, filterValue) => {
    try {

      const seasonRes = await axios.get(getGetCurrentSeasonUrl);
      const seasonId = seasonRes.data.currentSeason.id;
      const receipts = await fetchData(selectedFilterStatus, filterType, filterValue, seasonId, orderBy, limit, page);
      setFilterType(filterType);
      setFilterValue(filterValue);      
      debug("receipts.data", receipts);

      if(receipts.data){
        setData(receipts.data);
        setPageCount(receipts.pagination.pageCount);
      }
      else {
        setData([]);
      }

    } catch (error) {
      console.error(error);
    }
  }

  const handlePrePage = () => {
    handleChangePage(page - 1);
  };
  

  const handleNextPage = () => {
    handleChangePage(page + 1);
  };

  const handleChangePage = (page)=> {
    debug("change to page" + page);
    localStorage.setItem('pageId', page);
    setPage(page);
  }

  const changeSort = (type) => {
    setOrderBy(type);
    localStorage.setItem('dateSort', type);
  }

  const attributesParms = [
    { label: "User id", value: "user_id" },
    { label: "owner name", value: "owner" },
    { label: "Receipt id", value: "receipt_id"},
    { label: 'Receipt Type', value: "input_point"},
    { label: "Shop Name", value: "input_shop_name" },
    { label: "Receipt Number", value: "input_receipt_number"},
    { label: 'added Point', value: 'added_point'},
    //{ label: "remain Days Add Point", value: "remain_days_add_point"}, 
  ];

  const statusParms = [
    { label: "awaiting_approval", value: "awaiting_approval" },
    { label: "auto_approved_by_match", value: "auto_approved_by_match" },
    { label: "manual_approved", value: "manual_approved" },
    { label: "manual_rejected", value: "manual_rejected" },
    { label: "require_manual_approve", value: "require_manual_approve" },
  ];

  const handlePickerChange = (event) => {
    if(event.target.value === ''){
      setSearchTerm('');
      debug("clear");
    }
    setSelectedFilterParam(event.target.value);
    localStorage.setItem('filterParam', event.target.value);
  };

  const handleStatusChange = (event) => {
    if(event.target.value === ''){
      debug("no selected status");
    }
    setSelectedFilterStatus(event.target.value);
    localStorage.setItem("filterStatus", event.target.value);
    debug("selected status", event.target.value);
  };

  const handleSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    if(selectedFilterParam !== '' || selectedFilterParam !== null) {
      localStorage.setItem('searchTerm', event.target.value);
    }
    else {
      localStorage.removeItem('searchTerm');
    }
  };

  const handleInputPage = (event) => {
    if((event.target.value > 0 && event.target.value <= pageCount) || 
        event.target.value === ''){
      setInputPage(event.target.value);
    }
  };

  const changePage = ()=>{
    if(inputPage !== '')
      handleChangePage(parseInt(inputPage));
  }

  const handleFilter = (clickBySearch=true) => {
    //var filtered = null;
    debug("handle filter", selectedFilterParam);
    debug("handle filter", searchTerm);

    const isValidNum = Number(searchTerm);

    switch(selectedFilterParam){
        case "user_id":
          handleFilterParams("user_id", parseInt(isValidNum ? searchTerm : null));
          break;
        case "owner":
          handleFilterParams("owner", searchTerm !== '' ? searchTerm : '');
          break;
        case "receipt_id":
          handleFilterParams("receipt_id", parseInt(isValidNum ? searchTerm : null));
          break;
        case "input_point":
          handleFilterParams("input_point", parseInt(isValidNum ? searchTerm : null));
          break;
        case "input_shop_name":
          handleFilterParams("input_shop_name", searchTerm !== '' ? searchTerm : null);
          break;
        case "input_receipt_number":
          handleFilterParams("input_receipt_number", searchTerm !== '' ? searchTerm : null);
          break;
        case "added_point":
            handleFilterParams("added_point", parseInt(searchTerm !== '' ? searchTerm : ''));
          break;
        case "remain_days_add_point":
            handleFilterParams("remain_days_add_point", parseInt(isValidNum ? searchTerm : null));
          break;
        default: 
          handleFilterParams(null, null);
          break;
    }

    if(clickBySearch) {
      setPage(1);
    }
    else {
      if(selectedFilterParam === '' || selectedFilterParam === null) {
        localStorage.removeItem('searchTerm');
      }
    }
  };


  /*const handleFilterStatus = (_filteredData) => {
    var filtered = null; 
      switch(selectedFilterStatus){
          case "awaiting_approval":
            filtered = _filteredData.filter(item => {
              return item.attributes.status === "awaiting_approval";
            });
            break;
          case "auto_approved_by_match":
            filtered = _filteredData.filter(item => {
              return item.attributes.status === "auto_approved_by_match";
            });
            break;
          case "manual_approved":
            filtered = _filteredData.filter(item => {
              return item.attributes.status === "manual_approved";
            });
            break;
          case "manual_rejected":
            filtered = _filteredData.filter(item => {
              return item.attributes.status === "manual_rejected";
            });
            break;
          case "require_manual_approve":
            filtered = _filteredData.filter(item => {
              return item.attributes.status === "require_manual_approve";
            });
            break;
          default: 
            filtered = _filteredData;
            break;
      }
      debug("filter data", filtered);
      setFilteredData(filtered);
  };*/

  const sortedReceipts = (receipts) => {
    return receipts.sort((a, b) => {
      if(orderBy === 'ASC'){
        return new Date(a.publishedAt) - new Date(b.publishedAt);
      }
      else {
        return new Date(b.publishedAt) - new Date(a.publishedAt);
      }
    })
  };

  //const sortedDataWithFiltered = sortedReceipts(filteredData);
  const sortedDataNormal = sortedReceipts(data);

  const refresh = refreshPage();

  return (
    <div>
      <br/>
      <span>Filter keys:&nbsp;</span>
      <select value={selectedFilterParam} onChange={handlePickerChange}>
          <option value="">Select an option</option>
          {attributesParms.map((option) => (
            <option key={v4()} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      <span> Keywords:&nbsp;</span><input type="text" value={searchTerm} onChange={handleSearchTerm} />
      <span>&nbsp;&nbsp;Status:&nbsp;</span>
      <select value={selectedFilterStatus} onChange={handleStatusChange}>
          <option value="">find a status</option>
          {statusParms.map((option) => (
            <option key={v4()} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      <Button style={{marginLeft: '5px', marginBottom: '5px'}} onClick={handleFilter}>Search</Button>
      <Button style={{marginLeft: '335px', marginBottom: '5px'}} onClick={refresh}>ReLoad</Button>
      

      <div>
        <Button style={{marginLeft: '5px', marginBottom: '5px'}} onClick={() => changeSort('ASC')} disabled={orderBy === 'ASC'}>Date ASC</Button>
        <Button style={{marginLeft: '5px', marginBottom: '5px'}} onClick={() => changeSort('DESC')} disabled={orderBy === 'DESC'}>Date DESC</Button>
        <span> &nbsp;&nbsp;&nbsp;set Page:&nbsp;</span><input type="text" value={inputPage} min={"1"} onChange={handleInputPage} style={{width: '50px', textAlign: 'center'}} />
        <Button style={{marginLeft: '5px', marginBottom: '5px'}} onClick={changePage}>change</Button>
      </div>

      <div className="bottomPageSwitch">
        <Button onClick={handlePrePage} disabled={page === 1}>Pre</Button>
        <span>{page}</span>
        <Button onClick={handleNextPage} disabled={page >= pageCount}>Next</Button>
      </div>

      <table>
        <thead>
          <tr>
            {column.map((item) => <TableHeadItem key={v4()} item={item} />)}
          </tr>
        </thead>
        <tbody>
        {/*selectedFilterParam !== '' || selectedFilterStatus !== ''
          ? sortedDataWithFiltered.map((item) => <TableRow key={v4()} item={item} column={column} pageId={page}/>)
          : */sortedDataNormal.map((item) => <TableRow key={v4()} item={item} column={column} pageId={page}/>)}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable