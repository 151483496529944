import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout =() => {
    const navigate = useNavigate();
    console.log("logout!");
    useEffect(()=> {
        localStorage.setItem("user", "");
        navigate("/login");
    }, [navigate]);
  
    return null;
}

export default Logout;