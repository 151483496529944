import React, { useState } from 'react';
import QrReader from 'react-web-qr-reader';
import { Row, Button} from 'reactstrap';
import CryptoJS from "crypto-js";
import MediaQuery from 'react-responsive'
import {useNavigate} from 'react-router-dom';
import axios from "axios";
//import {userData} from "../../helpers";
import { updatePrizeRecordApi, env, host} from "../../config";

const currentEnvironment = env();
const Host = host();
const ENCRYPT_ACCESS_KEY = 'MGVFHD@$%&LOP20230322./';

function debug (message=null, variable=null){
  if(currentEnvironment !== "prod"){
    if(variable) {
      console.log(message, variable);
    }
    else {
      console.log(message);
    }
  }
}

const QRscanner = () => {
  const delay = 100;
  const [prizeStatus, setPrizeStatus] = useState('');
  const [redeemAmount, setRedeemAmount] = useState(null);
  const [facingMode, setFacingMode] = useState('environment');
  const [shouldRender, setShouldRender] = useState(true);
  const [result, setResult] = useState('No result');
  const [decryedResult, setDecryedResult] = useState('No result');
  const [prizeResult, setPrizeResult] = useState('No result');
  const navigate = useNavigate();

  const handleFacingModeChange = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === 'user' ? 'environment' : 'user'
    );
  };

  const handleScan = (scanned) => {
    if(scanned.data) {
      setPrizeStatus('');
      setRedeemAmount(null);
      setResult(scanned.data);
      var decryedData = getdecryptData(scanned.data).replace(/\s/g, "");
      console.log("decryedData", decryedData);
      var jsonResult = JSON.parse(decryedData);    
      setDecryedResult(jsonResult);
      getPrizeRecord();
    }
  };

  const getDecryedResult =(name)=> {
    if(decryedResult !== 'No result') {
      var JsonObj = JSON.parse(decryedResult);
      switch(name){
        case "userid":
          return JsonObj.userid;
        case "username":
            return JsonObj.username;
        case "userrealname":
              return JsonObj.userrealname;
        case "userHKID":
              return JsonObj.userHKID;
        case "usercontactnumber":
              return JsonObj.usercontactnumber;
        case "prizerecordid":
              return JsonObj.prizerecordid;
        case "prize_id":
              return JsonObj.prize_id;
        case "prize_title":
              return JsonObj.prize_title;
        case "usedPoints":
              return JsonObj.usedPoints;
        default:
          return "";
      }
    }
    else {
      return "";
    }
  }

  const getPrizeRecord = ()=> {
    if(decryedResult !== 'No result') {
      try {
        var JsonObj = JSON.parse(decryedResult);
        var GetPrizeRecordsApi =`${Host}/api/prize-records/`;
        fetch(GetPrizeRecordsApi + JsonObj.prizerecordid)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            setPrizeResult('Invalid / OutDated QR code');
            throw new Error('Prize record not found');
          }
        })
        .then((data) => {
          if(data.data) {
            debug("get prize record status: ", data.data.attributes.prize_status);
            setPrizeStatus(data.data.attributes.prize_status);
            setPrizeResult(data.data.attributes.prize_status);
            setRedeemAmount(data.data.attributes.redeem_amount);
          }
          else {
            setPrizeResult('Invalid / OutDated QR code');
          }
        })
        .catch((error) => {
          debug('Error fetching user points:', error);
          setPrizeResult('Invalid / OutDated QR code');
        });
      }catch (error) {
        debug('Error parsing JSON:', error);
        setPrizeResult('Invalid / OutDated QR code');
      }

    }
  }

  const handleDirectChange = () => {
    if(result) {
      try {
        var decryedData = getdecryptData(result).replace(/\s/g, "");
        console.log("decryedData", decryedData);
        var jsonResult = JSON.parse(decryedData);
        setDecryedResult(jsonResult);
        getPrizeRecord();
      }
      catch(error){
        var errorMsg = error.toString().replace("SyntaxError:", "");
        setDecryedResult(errorMsg);
      }
    }
  }

  const handleError = (error) => {
    console.log(error);
  };

  const handleResultChange = event => {
    setResult(event.target.value);
    //console.log("result", event.target.value);
  };

  /*const handleDecryedResultChange = event => {
    setDecryedResult(event.target.value);
  };*/

  const handlePrizeResultChange = event => {
    setPrizeResult(event.target.value);
  };

  //get derypt data
  const getdecryptData = (data)=> {
      var bytes  = CryptoJS.AES.decrypt(data, ENCRYPT_ACCESS_KEY);
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText;
  }

  //get encrypt data
  /*const getEncryptData = (data)=> {
    var cipherUserData = CryptoJS.AES.encrypt(data, ENCRYPT_ACCESS_KEY).toString();
    return cipherUserData;
  }*/

  const backToHomePage = async() => {
      setShouldRender(false);
      console.log("shouldRender", shouldRender);
      await navigate("/");
  };

  const reset = async() => {
    console.log("reset data");
    setResult('No result');
    setDecryedResult('No result');
    setPrizeResult('No result');
    setPrizeStatus('');
    setRedeemAmount(null);
};

  const updatePrizeStatus = () =>{
    if(decryedResult !== 'No result'){
      
      var JsonObj = JSON.parse(decryedResult);
      const url = updatePrizeRecordApi(JsonObj.prizerecordid);
      let data = JSON.stringify({
        "data": {
          "prize_status": "collected",
        }
      });
      
      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: url,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios.request(config)
      .then((response) => {
        var prizeNewStatus = JSON.stringify(response.data);
        console.log(prizeNewStatus);
        setPrizeResult(prizeNewStatus);
      })
      .catch((error) => {
        console.log(error);
        var errorString = "invalid qr code, the qr code generate is old version!";
        setPrizeResult(errorString);
      });

    }

  };

  return (
    <div className="qrCanvas">
      <Row className='button-group'>
      <Button className='return' onClick={backToHomePage}>Return</Button>
      <Button className='return' onClick={reset}>clear</Button>
      <Button className='return' onClick={handleFacingModeChange}>{facingMode === 'user' ? 'rear' : 'front'}</Button>
      </Row>
      <Row>
      <MediaQuery minWidth={300}>
      { shouldRender && 
        <QrReader className="qrReader"
            delay={delay}
            facingMode={facingMode}
            style={{ height:375, width: 400 }}
            onError={handleError}
            onScan={handleScan}
        />
      }
      </MediaQuery>
      </Row>
      <Row>
      <label className='label-underline'>Scanned code:</label>
      <textarea name="qrCode" value={result} rows={4} cols={20} onChange={handleResultChange} />
      </Row>
      <br/>
      <Row>
        <Button className='qrCodeBtn' onClick={handleDirectChange}>manual-convert</Button>
      </Row>
      <Row>
      <label className='label-underline'>Prize Details:</label>
      {/*<textarea name="decrypttext" value={decryedResult} rows={4} cols={20} onChange={handleDecryedResultChange}/>*/}
      <label>User Id: <span className='qrCodeLabel'>&nbsp; {getDecryedResult("userid")}</span></label>
      <label>User Name:  <span className='qrCodeLabel'>&nbsp;{getDecryedResult("username")}</span></label>
      <label>User Real Name: <span className='qrCodeLabel'>&nbsp;{getDecryedResult("userrealname")}</span></label>
      <label>User HKID: <span className='qrCodeLabel'>&nbsp;{getDecryedResult("userHKID")}</span></label>
      <label>User Contact Number: <span className='qrCodeLabel'>&nbsp;{getDecryedResult("usercontactnumber")}</span></label>
      <label>Prize Record Id: <span className='qrCodeLabel'>&nbsp;{getDecryedResult("prizerecordid")}</span></label>
      <label>Prize Id: <span className='qrCodeLabel'>&nbsp;{getDecryedResult("prize_id")}</span></label>
      <label>Prize Title: <span className='qrCodeLabel'>&nbsp;{getDecryedResult("prize_title")}</span></label>
      <label>Used Points: <span className='qrCodeLabel'>&nbsp;{getDecryedResult("usedPoints")}</span></label>
      <label>Redeem Amount: <span className='qrCodeLabel'>&nbsp;{redeemAmount}</span></label>
      </Row>
      <br/>
      <Row>
      <label className='label-underline'>Responsive result:</label>
      <textarea name="prizeNewStatus" value={prizeResult} rows={4} cols={20} onChange={handlePrizeResultChange}/>
      </Row>
      <br/>
      <Row>
        <Button className='qrCodeBtn' onClick={updatePrizeStatus} disabled={prizeStatus === 'collected'}>update-prize-status</Button>
      </Row>
    </div>
  );
};
  
  export default QRscanner;