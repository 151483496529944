import {HashRouter, Route, Routes} from 'react-router-dom';
import { Container } from 'reactstrap';
import Home from "./components/Home"
import Login from "./components/Login"
import Logout from './components/Logout';
import Registration from './components/Registration';
import {ToastContainer} from "react-toastify";
import { Protector } from './helpers';

import QRscan from './components/QRCode';
import AllUserPointsTable from './components/GetAllUserPoint';
//import UserPoints from './components/CheckUserPoint'

function App() {
  return (
      <Container>
        <HashRouter>
        <Routes>
          <Route path='/' element={<Protector Component={Home}/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/logout' element={<Logout/>}/>
          <Route path='/registration' element={<Registration/>}/>
          <Route path='/qrScan' element={<QRscan/>}/>
          <Route path='/userPoints' element={<AllUserPointsTable/>}/>
        </Routes>
        <ToastContainer/>
        </HashRouter>
      </Container>
  );
}

export default App;
