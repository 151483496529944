//Host and all api url:
const Env = "dev";

var Host = () => {
    var host = "";
    switch(Env){
        case "local":
            host = "http://localhost:1337";
            return host;
        case "dev":
            host = "https://dev-strapi.mgv.hk";
            return host;
        case "uat":
            host = "https://lss-cms-uat.good2share.app";
            return host;
        case "prod":
            host = "https://less-salt-cms.good2share.app";
            return host;
         default:
            host = "http://localhost:1337";
            return host;
    }
}

var PostLoginApi =`${Host()}/api/auth/local`;
var GetCurrentSeasonApi = `${Host()}/api/seasons/current_id`;
//const GetReceiptsApi =`${Host()}/api/receipts?pagination[pageSize]=100&populate=owner`;
var GetReceiptsApi =`${Host()}/api/receipts/contain?populate=owner`;
var RegisterAccountApi =`${Host()}/api/auth/local/register`;
var GetConfigApi =`${Host()}/api/configs`;
var GetPrizeRecordsApi =`${Host()}/api/prize-records?populate=owner`;


export const host = () => {
    return Host();
}

export const env = () => {
    return Env;
}

export const postLoginApi = () => {
    return PostLoginApi;
}

export const updatePrizeRecordApi = (prize_id) =>{
    return `${Host()}/api/prize-records/${prize_id}`;
}

export const updateReceiptApi = (receipt_id) =>{
    return `${Host()}/api/receipts/${receipt_id}`;
}

export const getGetCurrentSeasonApi = () => {
    return GetCurrentSeasonApi;
}

export const getReceiptsApi = () => {
    return GetReceiptsApi;
}

export const getPrizeRecordsApi = () => {
    return GetPrizeRecordsApi;
}

export const getConfigApi = () => {
    return GetConfigApi;
}

export const registerAccountApi = () => {
    return RegisterAccountApi;
}