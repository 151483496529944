import {host, getGetCurrentSeasonApi, getReceiptsApi, env } from "../../config";
import axios from "axios";
import {Button, Row} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import { useEffect, useState } from "react";
import {userData} from "../../helpers";

const currentEnvironment = env();
const getHostName = host();
const getReceiptsUrl = getReceiptsApi();
const getGetCurrentSeasonUrl = getGetCurrentSeasonApi();
const getPrizeRecordsUrl = host() + `/api/prize-records`;


function debug (message=null, variable=null){

    if(currentEnvironment !== "prod"){
      if(variable) {
        console.log(message, variable);
      }
      else {
        console.log(message);
      }
    }
}

function RefreshPage() {
    window.location.reload();
}
  
export const refreshPage = () => {
    return RefreshPage;
}

/*
const AllUserPointsTable = () => {
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [seasonId, setSeasonId] = useState(null);
    const [userPoints, setUserPoints] = useState(0);
    const [earnedPoints, setEarnedPoints] = useState(null);
    const [calculatePoints, setCalculatePoints] = useState(null);
    const [deductPoints, setDeductPoints] = useState(null);
    const {jwt} = userData();
    const navigate = useNavigate();
    var deductedPoints = 0;
  
    const handleInputChange = (event) => {
        if(event.target.value.length === 0) {
            handleReset();
        }
        else {
            setUserPoints(0);
            setUserName('');
            setCalculatePoints(null);
            setEarnedPoints(null);
            setDeductPoints(null);
            setUserId(event.target.value);
        }
    };

    const handleReset = () => {
        setUserId('');
        setUserName('');
        setUserPoints(0);
        setCalculatePoints(null);
        setDeductPoints(null);
        setEarnedPoints(null);
        deductedPoints = 0;
      };

    useEffect(() => {
        const getSeasonId = async () => {
          try {
            const seasonRes = await axios.get(getGetCurrentSeasonUrl);
            const currentSeasonId = seasonRes.data.currentSeason.id;
            debug("season id: ", currentSeasonId);
            setSeasonId(currentSeasonId);
          } catch (error) {
            debug('Error fetching current season ID:', error);
          }
        };
    
        getSeasonId();
      }, []);

    const findUserData = () => {
        fetch(getHostName + `/api/users?populate=*`)
          .then((response) => response.json())
          .then((data) => {

           const user = data.find((user) => user.id === parseInt(userId));

           if(user){
                setUserName(user.username);
                if(user.user_season_points){
                    for(var seasonPoint of user.user_season_points){
                        debug("seasonPoint", seasonPoint);
                        if(seasonPoint.season_id === seasonId){
                            debug("seasonPoint", seasonPoint.point);
                            setUserPoints(seasonPoint.point);
                        }
                    }
               }
               else {
                 setUserPoints(0);
               }  
           }
           else {
                setUserName('');
           } 
           
          })
          .then(() => {
            // Call prize record API here
            fetch(getPrizeRecordsUrl + `?filters[user_id]=`+ userId +`&filters[season]=` + seasonId)
              .then((response) => response.json())
              .then((data) => {
                var prizes = data.data;
                deductedPoints = 0;
                debug("prize records:", prizes);
                setDeductPoints(deductedPoints); 

                if(prizes.length > 0 && prizes){
                    for(var prize of prizes){
                        deductedPoints += prize.attributes.deductPoints;
                    }
                    debug("deductPoints:", deductedPoints);           
                    setDeductPoints(deductedPoints);      
                }
              })
              .then(()=> {
                fetch(getReceiptsUrl + `&filters[season]=`+seasonId+`&filters[user_id]=`+ userId + `&pagination[limit]=200&pagination[start]=0`)
                    .then((response) => response.json())
                    .then((data) => { 
                        var receipts = data.data;
                        var totalAddedPoints = 0;
                        //debug("receipt's added_points:", receipts);
                        if(receipts.length > 0 && receipts){
                            for(var receipt of receipts){
                                totalAddedPoints += receipt.attributes.added_point;
                            }
                            setEarnedPoints(totalAddedPoints);
                            totalAddedPoints = totalAddedPoints - deductedPoints;
                            setCalculatePoints(totalAddedPoints);
                            debug("receipt's total_added_points:", totalAddedPoints);
                        }
                        else {
                            setEarnedPoints(0);
                            setCalculatePoints(0);
                        }
                    })
                    .catch((error) => {
                        debug('Error fetching data from the second API:', error);
                        setEarnedPoints(0);
                        setCalculatePoints(0);
                    });
              })
              .catch((error) => {
                debug('Error fetching user points:', error);
              });
          })
          .catch((error) => {
            debug('Error fetching user points:', error);
          });
    }
  
    const handleFormSubmit = (event) => {
        event.preventDefault();
        findUserData();
    };

    const backToHomePage = async() => {
        await navigate("/");
    };

    async function updatePoints() {
        try {
            axios.get(getHostName+'/api/user-season-points?populate=owner&filters[owner]='+userId+'&filters[season_id]='+seasonId, {
                headers: {
                  Authorization: `Bearer ${jwt}`,
                },
              })
              .then(response => {
                // handle success
                const item = response.data; // Assuming the response contains the item you want to update
                const seasonPointData = item.data[0];
                debug("seasonPointData", seasonPointData);
                if(item && seasonPointData) {
                  // Update the item             
                  axios.put(getHostName+'/api/user-season-points/'+ seasonPointData.id, {
                    data: {
                        point: calculatePoints
                    },
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                  })
                  .then(response => {
                    // handle success
                    findUserData();
                    debug("success update", response.data);                   
                  })
                  .catch(error => {
                    // handle error
                    debug("failure update", error);
                  });
                } else {
                  debug("Item not found");
                }
              })
              .catch(error => {
                // handle error
                debug("failure to retrieve item", error);
              });

        } catch (error) {
            debug("failure to retrieve item", error);
        }
    };

    return (
      <div className="checkUserPointCanvas">
        <Row className='button-group'>
            <Button className='return' onClick={backToHomePage}>Return</Button>
        </Row>
        <br/>
        <Row>
            <form onSubmit={handleFormSubmit}>
            <label>
                User ID: &nbsp;
                <input type="text" value={userId} onChange={handleInputChange} />
            </label>
            &nbsp;
            &nbsp;
            <Button type="submit">Get Points</Button>
            &nbsp;
            &nbsp;
            <Button type="submit" onClick={handleReset}>Reset</Button>
            </form>
        </Row>
        {userName !== '' ? (
          <table>
            <thead>
              <tr>
                <th>User ID</th>
                <th>User Name</th>
                <th>Current Season Points</th>
                <th>Receipts Earned Points</th>
                <th>Redeemed Season Points</th>
                <th>Actual Remain Season Points</th>
                <th>Manual Modify</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{userId}</td>
                <td>{userName}</td>
                <td>{userPoints}</td>
                <td>{earnedPoints}</td>
                <td>{deductPoints}</td>
                <td>{calculatePoints}</td>
                <td><Button className="modify-button" onClick={updatePoints} disabled={userPoints === calculatePoints || calculatePoints < userPoints}>Modify</Button></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>No user points found for the given user ID.</p>
        )}
      </div>
    );
  };
  */
  const AllUserPointsTable = () =>{
    const perPage = 50;
    const previousPage = localStorage.getItem('userPointPageId');
    const [currentPage, setCurrentPage] = useState(previousPage? parseInt(previousPage) : 1);
    const [pageCount, setPageCount] = useState(0);
    const [seasonId, setSeasonId] = useState(null);
    const [userIds, setUserIds] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [userNames, setUserNames] = useState([]);
    const [userPoints, setUserPoints] = useState([]);
    const [earnedPoints, setEarnedPoints] = useState([]);
    const [redeemPoints, setRedeemPoints] = useState([]);
    const [calculatePoints, setCalculatePoints] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const startIndex = (currentPage - 1) * perPage;

    const {jwt} = userData();
    const navigate = useNavigate();

    const backToHomePage = async() => {
        await navigate("/");
        await localStorage.removeItem('userPointPageId');
        await localStorage.removeItem('seasonId');
    };

    const changePage = (page) => {
      setCurrentPage((prevPage) => {
        const newPage = Math.max(prevPage + page, 1);
        if(newPage >= 0)
          localStorage.setItem('userPointPageId', newPage);
        return newPage;
      });
    };

    const refresh = refreshPage();

    const changeSeasonTap = (season_id) => {
      localStorage.setItem('seasonId', season_id);   
      localStorage.removeItem('userPointPageId');  
      refresh();
    };

    async function updatePoints(userId, rowId) {
        try {
            axios.get(getHostName+'/api/user-season-points?populate=owner&filters[owner]='+userId+'&filters[season_id]='+seasonId, {
                headers: {
                  Authorization: `Bearer ${jwt}`,
                },
              })
              .then(response => {
                // handle success
                const item = response.data; // Assuming the response contains the item you want to update
                const seasonPointData = item.data[0];
                debug("seasonPointData", seasonPointData);
                if(item && seasonPointData) {
                  // Update the item             
                  axios.put(getHostName+'/api/user-season-points/'+ seasonPointData.id, {
                    data: {
                        point: calculatePoints[rowId]
                    },
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                  })
                  .then(response => {
                    // handle success
                    window.location.reload();
                    debug("success update", response.data);                   
                  })
                  .catch(error => {
                    debug("failure update", error);
                  });
                } else {
                  debug("Item not found");
                }
              })
              .catch(error => {
                debug("failure to retrieve item", error);
              });

        } catch (error) {
            debug("failure to retrieve item", error);
        }
    };


    useEffect(() => {
        const getSeasonId = async () => {
            try {

              debug("currentPage: ", previousPage);

              const seasonResponse = await fetch(getGetCurrentSeasonUrl);
              const seasonRes = await seasonResponse.json();
              const allRedeemPoints = [];
              const allEarnedPoints = [];
              const allCalculatedPoints = [];

              if (seasonRes) {
                var currentSeasonId = seasonRes.currentSeason.id;
                const previousSeasonId =  localStorage.getItem('seasonId');       
                debug("previous seasonId id: ", previousSeasonId);     

                if(previousSeasonId){
                  var changedSeasonId = parseInt(previousSeasonId);
                  currentSeasonId = changedSeasonId;
                }
                setSeasonId(currentSeasonId);
                debug("season id: ", currentSeasonId);
          
                const usersResponse = await fetch(getHostName + '/api/users?populate=*&sort[1]=id');
                const data = await usersResponse.json();
                //const users = data.filter((user) => user.user_season_points.length > 0);
                const users = data.filter((user) => {
                  const userSeasonPoints = user.user_season_points.find((seasonPt) => seasonPt.season_id === currentSeasonId);
                  return userSeasonPoints !== undefined;
                });

                if (users) {
                  debug("users:", users);
                  setPageCount(Math.ceil(users.length / perPage));
                  const usernames = users.map((user) => user.username);
                  const userids = users.map((user) => user.id);
                  const userroles = users.map((user) => user.role.type);
                  const userpoints = users.map((user) => user.user_season_points.find((seasonPt) => seasonPt.season_id === currentSeasonId));

                  setUserIds(userids);
                  setUserRoles(userroles);
                  setUserNames(usernames);
                  setUserPoints(userpoints);

                  
                  const fetchPrizeRecords = userids.map((userId) => fetch(getPrizeRecordsUrl + `?filters[season]=` + currentSeasonId + `&filters[user_id]=`+ userId+ `&pagination[limit]=200&pagination[start]=0`));
                  const fetchReceipts = userids.map((userId) => fetch(getReceiptsUrl + `&filters[season]=` + currentSeasonId + `&filters[user_id]=` + userId + `&pagination[limit]=200&pagination[start]=0`));

                  const prizeRecordsResponses = await Promise.all(fetchPrizeRecords);
                  const receiptsResponses = await Promise.all(fetchReceipts);

                  for (let i = 0; i < userids.length; i++) {
                    const prizeRecordResponse = prizeRecordsResponses[i];
                    const receiptResponse = receiptsResponses[i];

                    const prizeRecordData = await prizeRecordResponse.json();
                    const receiptData = await receiptResponse.json();

                    if (prizeRecordData.data && receiptData.data) {
                      let totalRedeemPoints = 0;
                      let totalAddedPoints = 0;

                      debug("allPrizeRecordData " + userids[i] + ":", prizeRecordData.data);
                      for (let prizeRecord of prizeRecordData.data) {
                        if (prizeRecord.attributes)
                          totalRedeemPoints += prizeRecord.attributes.deductPoints;
                      }

                      for (let receipt of receiptData.data) {
                        totalAddedPoints += receipt.attributes.added_point;
                      }

                      allRedeemPoints.push(totalRedeemPoints);
                      allEarnedPoints.push(totalAddedPoints);
                      totalAddedPoints = totalAddedPoints - totalRedeemPoints;
                      allCalculatedPoints.push(totalAddedPoints);
                    }
                  }

                  debug("allRedeemPoints:", allRedeemPoints);
                  debug("allEarnedPoints:", allEarnedPoints);
                  debug("allCalculatedPoints:", allCalculatedPoints);
                  setRedeemPoints(allRedeemPoints);
                  setEarnedPoints(allEarnedPoints);
                  setCalculatePoints(allCalculatedPoints);
                  setIsButtonDisabled(false);
                } else {
                  setUserNames([]);
                  setUserPoints([]);
                  setUserIds([]);
                  setEarnedPoints([]);
                }
              }
            } catch (error) {
              debug('Error fetching current season ID:', error);
            }
        };

        getSeasonId();
      }, [previousPage]);
      
      return (
        <div className="checkUserPointCanvas">
          <Row className='button-group'>
              <Button className='return' onClick={backToHomePage}>Return</Button>
          </Row>
          <Row className='button-group'>
              <Button className='return' style={{ marginLeft: 'auto', bottom: '0px' }} onClick={refresh}>ReLoad</Button>
          </Row>
          <br/>
          <div className="bottomPageSwitch">
                <Button
                  onClick={()=>changeSeasonTap(1)}
                  disabled={seasonId === 1}
                >
                  Season 1
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={()=>changeSeasonTap(2)}
                  disabled={seasonId === 2}
                >
                  Season 2
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={()=>changeSeasonTap(3)}
                  disabled={seasonId === 3}
                >
                  Season 3
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={()=>changeSeasonTap(4)}
                  disabled={seasonId === 4}
                >
                  Season 4
                </Button>
          </div>
          <br/>
          <div className="bottomPageSwitch">
                <Button
                  onClick={() => changePage(-1)}
                  disabled={currentPage === 1}
                >
                  Pre
                </Button>
                <span>{currentPage}</span>
                <Button
                  onClick={() => changePage(1)}
                  disabled={currentPage === pageCount || pageCount === 0}
                >
                  Next
                </Button>
          </div>
          {userPoints.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>User ID</th>
                  <th>User Name</th>
                  <th>User Role</th>
                  <th>Current Season Points</th>
                  <th>Receipts Earned Points</th>
                  <th>Redeemed Season Points</th>
                  <th>Actual Remain Season Points</th>
                  <th>Manual Modify</th>
                </tr>
              </thead>
              <tbody>
              {userIds.slice(startIndex, startIndex + perPage).map((userid, index) => (
                <tr key={startIndex + index}>
                  <td>{startIndex + index}</td>
                  <td>{userid}</td>
                  <td>{userNames[startIndex + index]}</td>
                  <td>{userRoles[startIndex + index]}</td>
                  <td>{(userPoints[startIndex + index]?.point || 0)}</td>
                  <td>{earnedPoints[startIndex + index]}</td>
                  <td>{redeemPoints[startIndex + index]}</td>
                  <td>{calculatePoints[startIndex + index]}</td>
                  <td><Button 
                      className="modify-button" 
                      onClick={()=>updatePoints(userid, startIndex + index)} 
                      disabled={
                        isButtonDisabled ||
                        calculatePoints[startIndex + index] === (userPoints[startIndex + index]?.point || 0) || 
                        redeemPoints[(startIndex + index)] > (earnedPoints[(startIndex + index)])
                      }>
                        Modify
                      </Button>
                  </td>
                </tr>
              ))}
              </tbody>      
            </table>
            ) : (
                <p>No user points found for the given user ID.</p>
              )}
        </div>
      );
  }

  export default AllUserPointsTable;