import React from "react";
import CustomNav from "../CustomNav";
import DataTable from '../Receipt/table';
import {Row} from 'reactstrap';

const Home =() => {

   
    return (
        <div>
          <CustomNav/>
          <div className="home">
            <Row className="homepageTitle"></Row>
            <DataTable/>
          </div>
        </div>
      );
}

export default Home;