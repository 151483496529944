import React, {useState} from "react"
import {userData} from "../../helpers";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { Link } from "react-router-dom";

const CustomNav =() => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle =()=> setIsOpen(!isOpen);
    const {username} = userData();
    const logout =()=> localStorage.clear();
    
    return (
        <div className="custom-nav">
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/" className="mr-auto">
            <b>Good2share-App-Admin</b> <br/>
            <b> username: {username} </b>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} className="mr-2" />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <div><Link to='/userPoints'>Check User Points</Link></div>
                <div><Link to='/qrScan'>QR Scan </Link></div>
                <div><Link to="/logout" onClick={logout}>Logout </Link></div>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      );
}

export default CustomNav;