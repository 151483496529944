import React, {useState} from "react";
import { Col, Row, Button, FormGroup, Input} from 'reactstrap';
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from 'react-router-dom';
import { storeUser } from "../../helpers";
import { postLoginApi, host, env } from "../../config";

const postLoginUrl = postLoginApi();

const getUserDataUrl = host() + "/api/users/";

const initialUser = {password: "", identifier: ""};

const currentEnvironment = env();

function debug (message=null, variable=null){

    if(currentEnvironment !== "prod"){
      if(variable) {
        console.log(message, variable);
      }
      else {
        console.log(message);
      }
    }
  }

const Login =() => {
    const [login, setLogin] = useState(false);
    const [user, setUser] = useState(initialUser);  
    const navigate = useNavigate();

    const handleChange = ({target}) => {
        const {name, value} = target;
        setUser((currentUser) =>({
            ...currentUser,
            [name]:value,
        }))
    };
    const handleLogin = async() => {
        if(user.identifier && user.password && !login){
            //const {data} = await axios.post(postLoginUrl, user);
            await axios.post(postLoginUrl, user).then(response => {
                // handle success
                var userData = response.data;
                debug("data", userData);
                if(userData.jwt){
                    return axios.get(getUserDataUrl + userData.user.id + "?populate=*").then(user => {
                        //debug("role", role);
                        debug("user data", user.data.role);
                        if(user){
                            if(user.data.role.type === "authenticated"){
                                storeUser(userData);
                                toast.success("Logged in successfully", {
                                    hideProgressBar: true,
                                });
                                setUser(initialUser);
                                setLogin(true);
                                navigate("/");
                            }
                            else {                                
                                toast.error("The account is not authenticated to login!", {
                                    hideProgressBar: true,
                                });
                                setLogin(false);
                            }
                        }
                    }).catch(error => {
                        debug(error);
                        toast.error("Cannot get the role of account!", {
                            hideProgressBar: true,
                        });
                        setLogin(false);
                    });
                }
              })
              .catch(error => {
                debug(error);
                toast.error("Wrong Passeword or account is not activitated. Please verify your email first!", {
                    hideProgressBar: true,
                });
                setLogin(false);
              });
            }
        }

    return (
        <Row className="login">
            <Col sm="12" md={{size:4, offset:4}}>
            <h4>Good2share-App-Admin Login:</h4>
                <div>
                    <FormGroup>
                        Email:
                        <Input 
                        type="email" 
                        name="identifier" 
                        value={user.identifier} 
                        onChange={handleChange} 
                        placeholder="Enter your email" />
                    </FormGroup>
                    <FormGroup>
                        Passeword:
                        <Input 
                        type="password" 
                        name="password" 
                        value={user.password} 
                        onChange={handleChange} 
                        placeholder="Enter password" />
                    </FormGroup>
                    <Button color="primary" onClick={handleLogin}>
                        Login
                    </Button>
                </div>
            </Col>
        </Row>
      );
}

export default Login;